<template>
<div>
    <label
        :class="{disabled : disabled}"
        v-if="label"
    >
        {{ label }}
        <help-button
            :name="name"
            @click="clicked(name)"
        />
    </label>
    <input 
        type="text"
        :value="value"
        @input="onInputValue"
        :disabled="disabled"
        :placeholder="placeholder"
        @keydown.enter="keydownEnter"
        v-if="unit != 'm2' && unit !='W/m2K' && !step"
    >
    <input 
        type="number"
        :step="parseFloat(step)"
        :value="value"
        @input="onInputValue"
        @change="onChangeValue"
        :disabled="disabled"
        @keydown.enter="keydownEnter"
        v-if="unit == 'm2' || unit == 'W/m2K' || step"
    >
    <span v-if="unit == '[m3/h]'">
         [m<sup>3</sup>/h]
    </span>
    <span v-else-if="unit">
        <span>{{ unit }}</span>
    </span>
    <span v-else-if="step">
        <span>-</span>
    </span>

    <span
        class="subscript"
        v-if="subscript"
    >
        {{ subscript }}
    </span>
</div>
</template>

<script>
/**
 * 汎用テキストボックス（単位付きも可）
 * 
 * [プロパティ]
 * label ... ラベルとして表示する内容。不要な場合は入力不要
 * unit ... 単位。不要な場合は入力不要
 * value ... 親コンポーネントで指定されたv-modelの値。明示しないとinput要素にvalueが入らない
 * subscript ... 単位よりも後に挿入される但し書き
 * disabled ... 入力の可否。不可ならtrue
 * step ... 数値入力の際のステップ値を指定する。数値入力の場合には基本的に必須。
 * name ... ヘルプ用ボタンのキー=項目名を扱う
 * placeholder ... プレースホルダを入れる
 * 
 * [イベント処理]
 * 入力時 ... this.$emit('input', event.target.value)
 * 入力内容確定時 ... this.$emit('change', event.target.value)
 * クリック時 ...  this.$emit('click', name)
 * 入力中にエンターキー押下 ... this.$emit('keydown-enter')
 * 
 * [利用例]
 * <app-text
 *     label="主たる居室"
 *     unit="m2"
 *     v-model="bsc_shukyoshitsu_menseki"
 *     :step="0.01"
 *     class="number"
 *     subscript="(小数点以下2桁)"
 * />
 */
export default {
    name:"AppText",
    props: {
        /**
         * ラベルとして入力部の左に表示する内容
         */
        label: {
            type:String
        },
        /**
         * 単位として入力部の右に表示する内容
         */
        unit: {
            type:String
        },
        /**
         * input要素内のvalueとして使用される値
         * コンポーネントを使用する際に v-model で 暗黙的に :value を使用するため明示する
         */
        value: {
            type:[String, Number],
            required:true
        },
        /**
         * 単位よりも後に挿入される但し書き
         */
        subscript:{
            type:String
        },
        /**
         * 入力の可否。不可ならtrue
         */
        disabled:{
            type:Boolean
        },
        /**
         * 数値入力の際のステップ値を指定する。
         */
        step: {
            type:Number
        },
        /**
         * ヘルプ用ボタンのキー=項目名を扱う
         */
        name:{
            type:String
        },
        /**
         * プレースホルダを入れる
         */
        placeholder:{
            type:String
        },
    },
    methods: {
        /**
         * 入力されたら親コンポーネントにイベント発生を通知（ヘルプ表示）
         * input イベントが発生して text が書き換わる際に
         * その変更を 正しく受け取れるようにイベントを送出する
         * type="text" の場合、$event.target.value 値として返す 
         * (computed get set で書いてもよい)
         * (子の中で値を書き換えないことが大事)
         */
        onInputValue: function(event) {
            this.$emit('input', event.target.value)
        },
        /**
         * 入力内容の変更が確定したら親コンポーネントにイベント発生を通知（ヘルプ表示）
         */
        onChangeValue: function(event) {
            this.$emit('change', event.target.value)
        },
        /**
         * クリックされたら親コンポーネントにイベント発生を通知（ヘルプ表示）
         */
        clicked(name){
            this.$emit('click', name)
        },
        /**
         * 親コンポーネントにエンターキーが押されたことを通知（検索ボタンを押さなくても検索が実行できる）
         */
        keydownEnter(){
            this.$emit('keydown-enter')
        }
    }
}
</script>

<style scoped>
div{
    margin: 0 0 4px 0;
}
label{
    margin-right: 6px;
    font-size: 12px;
    font-weight: bolder;
    color: #1C4373;
}
input[type="text"], input[type="number"]{
    line-height: 22px;
    font-size: 16px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 3px;
    border: 1px solid #aaa;
}
input.error{
    box-shadow: 0px 0px 3px 1px #F00;
}
span{
    margin-left: 3px;
    font-size: 12px;
    font-weight: bolder;
    color: #1C4373;
}
span.error{
    color:#F00;
    line-height:1em;
}

/* クラスごと */
.organization input[type="text"],
.email input[type="text"]{
    width: 20em;
}

.address input[type="text"]{
    width: 90%;
}
.number input[type="text"],
input[type="number"]{
    width: 5em;
    text-align: right;
}

/* disabledのとき */
label.disabled{
    color: #999;
}
input[type="text"]:disabled, input[type="number"]:disabled{
    color: rgb(84, 84, 84);
    background-color: #f3f3f3;
}
input[type="text"]:disabled ~ span,
input[type="number"]:disabled ~ span,
input[type="text"]:disabled ~ span span,
input[type="number"]:disabled ~ span span{
    color: #999;
}

.app_box.disabled input[type="text"],
.app_box.disabled input[type="number"],
.app_box.disabled input[type="adress"]{
    color: rgb(84, 84, 84);
    background-color: #eee;
}
.app_box.disabled label,
.app_box.disabled span{
    color: #999;
}
</style>