<template>
    <div :class="{disabled : !available, app_box:true}">
        <div class="grid_1">
            <span v-html="label"></span>
        </div>
        <div class="grid_2">
            <slot></slot>
        </div>
    </div>
</template>

<script>
/**
 * [プロパティ]
 * label ... 左側のボックスに表示するラベル
 * available ... ボックスをグレーアウトするかどうか。簡易で使う。
 * name ... ヘルプ表示用のキー
 * url ... リンク先url
 * link-to ... 表示名を指定する
 * 
 * [イベント処理]
 * ヘルプボタンクリック時 ... this.$emit('click', name)
 * 
 * [利用例]
 * <app-box
 *     :available="true"
 *     label="住宅タイプの名称" 
 *     class="flex_column"
 *     @click="helpView"
 *     name="bsc_name"
 *     url="https://~"
 *     link-to="tool"
 * >
 *     (ここに中身が入る)
 * </app-box>
 */
export default {
    name:"AppBox",
    props: {
        /**
         * 左側のボックスに表示するラベル
         */
        label:{
            type:String
        },
        /**
         * ボックスをグレーアウトするかどうか。簡易で使う。
         */
        available: {
            type:Boolean
        },
        /**
         * ヘルプ表示用のキー
         */
        name: {
            type:String
        },
        /**
         * リンクボタン表示用のキー
         */
        url: {
            type:String
        },
        /**
         * リンク先のタイプ（現状は[tool]1種類です。）
         */
        linkTo: {
            type: String,
        },
    },
    methods:{
        /**
         * ヘルプボタンのクリックイベントを親へ受け渡す
         */
        clicked(name){
            this.$emit('click', name)
        }
    }
}

</script>

<style scoped>
.app_box{
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 16px 22px;
    text-align: left;
    border-bottom:1px solid #ccc;
}
.app_box:last-of-type{
    border:none;
}
.grid_1{
    padding-bottom: 4px;
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: bolder;
    color: #1C4373;
    border-bottom:1px solid rgba(83, 191, 216, 0.5);
}

.grid_2{
     display: flex;
     flex-direction: column;
}
.row .grid_2{
     display: flex;
     flex-direction: row;
}
.app_box .grid_2 p{
    margin: 0;
}
div.info_link{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    padding: 0;
    font-size: 14px;
    line-height: 14px;
    font-weight: bolder;
    text-align: center;
    color: white;
    background-color: #178EAA;
    border-radius: 8px;
    border: none;
    box-shadow: none;
    cursor:pointer;
}

_:-ms-lang(x)::-ms-backdrop, a.info_link {
    display: inline-block;
    justify-content: none;
    padding-top: 4px;
    box-sizing: border-box;
}
.flex_column .grid_2{
    display: flex;
    flex-direction: column;
}

.flex_row .grid_2{
    display: flex;
    flex-direction: row;
}
/* disabled */
.app_box.disabled{
    background-color: rgba(0,0,0, 0.1);
}
.app_box.disabled .grid_1{
    color: #777;

}
/* app-boxでavailableがfalseのとき、押せないようにする */
.app_box.disabled >>> input,
.app_box.disabled >>> label,
.app_box.disabled >>> button{
    pointer-events: none;
}

/* レスポンシブ（タブレット縦よりウインドウ幅が狭いもの） */
@media screen and (max-width: 896px) {
.flex_row .grid_2{
    flex-direction: column;
}
.resp_br{
    display: none;
}

.app_box >>> .link_button{
    margin-left: 10px;
    margin-bottom: 3px;
}

}
</style>