<template>
  <div class="ly_tempDefault">
    <layout-header />
    <main class="bl_main ly_main">
        <slot></slot>
    </main>
  </div>
</template>

<script>
import LayoutHeader from '@/components/templates/LayoutHeader.vue'

export default {
  name: 'TempDefault',
  components:{
    LayoutHeader,
  },
  computed:{
  }
}
</script>

<style scoped>
.ly_main{
    margin: 0 auto;
    padding: 90px 32px 200px;
}
.bl_main{
    width: 100%;
    max-width: 1000px;
    min-height: 100vh;
    background-color: white;
}

/* 
下層ページの共通クラス
 */
 /* フォーム */
.ly_form{
    margin-bottom: 16px;
}
.ly_form2Columns{
    display: grid;
    display: -ms-grid;
    grid-template-columns: 1fr 400px;
    -ms-grid-colums: 1fr 400px;
}
.ly_form2Columns__col1{
    grid-column: 1;
    -ms-grid-column: 1;
}
.ly_form2Columns__col2{
    grid-column: 2;
    -ms-grid-column: 2;
}
.ly_form2Columns__col3{
    grid-column: 3;
    -ms-grid-column: 3;
}
.ly_form_container_field{
    display: grid;
    display: -ms-grid;
    grid-template-columns: 6rem 1fr;
    -ms-grid-colums: 6rem 1fr;
    width: calc(100% - 10px);
    margin: 0 auto;
    padding: 16px;
}
.ly_form_container_field__flex{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.ly_form_container_field_col1{
    grid-column: 1;
    -ms-grid-column: 1;
}
.ly_form_container_field_col2{
    grid-column: 2;
    -ms-grid-column: 2;
}
.bl_form_container{
    border: #53BFD8 solid 1px;
    border-radius: 5px;
}
.bl_form_container_field:not(:first-child){
    border-top: #53BFD8 solid 1px;
}
.bl_form_container_field_label{
    margin: 0;
    font-size: 14px;
    font-weight: 600;
}

_:-ms-lang(x)::-ms-backdrop, .ly_form_container_field{
   min-width: 650px;
   max-width: 650px;
}
_:-ms-lang(x)::-ms-backdrop,.bl_form_container_field_label{
    display: inline-block;
    min-width: 6rem;
}

/* レスポンシブ（タブレット縦よりウインドウ幅が狭いもの） */
@media screen and (max-width: 1000px) {
    .ly_form2Columns{
        grid-template-columns: 1fr;
        -ms-grid-colums: 1fr;
    }
    .ly_form2Columns__sizes{
        grid-template-rows: auto;
        -ms-grid-rows: auto ;
        grid-template-columns: 1fr;
        -ms-grid-colums: 1fr;
    }
    .ly_form2Columns__col2{
        grid-row: 2;
        -ms-grid-row: 2;
        grid-column: 1;
        -ms-grid-column: 1;
    }
    .ly_form_container_field{
        grid-template-columns: 1fr;
        -ms-grid-colums: 1fr;
    }
    .ly_form_container_field_col1{
        border-bottom: 1px solid #ccc; 
    }
    .ly_form_container_field_col2{
        grid-row: 2;
        -ms-grid-row: 2;
        grid-column: 1;
        -ms-grid-column: 1;
    }
    _:-ms-lang(x)::-ms-backdrop, .ly_form_container_field{
        min-width: 350px;
        max-width: 100%;
    }
}
</style>
