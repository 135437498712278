<template>
    <div class="information">
        <p
            :class="{title:true, none:!(title)}"
            v-html="title"
        />
        <p :class="{text:true, inline:!(title)}">
            <slot></slot>
        </p>
    </div>
</template>

<script>
/**
 * Informationを表示するためのボックス
 * 
 * [プロパティ]
 * title ... informationのタイトル。HTML形式を許容。
 * 
 * [利用例]
 * <information-box
 *     title="補正係数の計算に「
 *     <a
 *         href='http://house.app.lowenergy.jp/Tool/HeatExchangerTool'
 *         target='_blank'
 *     >
 *         温度交換効率の補正係数（C<sub>bal</sub> , C<sub>leak</sub>）の算出ツール
 *     </a>
 *     」(別ウィンドウに表示されます)を利用するか、 下記の値を入力してください。"
 *     class="margin"
 * >
 *    <ul>
 *         <li>給気と排気の比率による温度交換効率の補正係数：0.90</li>
 *         <li>排気過多時における住宅外皮経由の漏気による温度交換効率の補正係数：1.00</li>
 *     </ul>
 * </information-box>
 */
export default {
    name:"InformationBox",
    props:{
        /**
         * informationのタイトル。HTML形式を許容。
         */
        title:{
            type:String
        },
    }
}
</script>

<style scoped>
.information{
    border-radius: 0.5rem;
    background-color: rgba(96, 194, 218, 0.1);
    margin-top:0.5rem;
    padding:0.5rem;
}
.information > .title, .information > .text {
    margin:0;
    line-height: 1.5em;
    color:#1C4373;
    text-align: justify;
}
.title{
    font-weight: bolder;
}
.title::before{
    content: "i";
    display:inline-flex;
    width:1rem;
    height:1rem;
    align-items:center;
    justify-content: center;
    background-color:#1C4373;
    color:#fff;
    font-weight: bolder;
    font-size: 0.8em;
    border-radius: 1rem;
    margin:0.25rem 0.25rem 0.25rem 0;
}
.none, .inline{
    display: inline;
}
.information .text >>> p{
    margin-top: 0;
    margin-bottom: 0;
}
.text >>> a:hover{
    text-decoration: underline;
}
.information >>> a {
    font-weight: bolder;
}

.information >>> a.target_blank{
    display: inline-flex;
    justify-content: center;
    align-items: baseline;
    box-sizing: border-box;
    width: auto;
    margin-top: 4px;
    padding: 2px 8px;
    font-size: 12px;
    line-height: 12px;
    font-weight: bolder;
    text-align: center;
    color: #1C4373;
    background-color: white;
    border-radius: 8px;
    box-shadow: none;
    cursor:pointer;
}

.information >>> a.target_blank:after{
    content: "";
    display: inline-block;
    width: 12px;
    min-width: 12px;
    height: 12px;
    min-height: 12px;
    background: url(/img/all_icons.png) no-repeat; /* スプライト呼び出し */
    background-size: auto calc( 225 / 72 * 100% );
    background-position-x: calc(142 / (1725 - 1015) * 100%);
    background-position-y: calc(120 / (225 - 72) * 100%);
}

.information >>> a.target_blank:hover{
    color: white;
    background-color: #1C4373;
}

.information >>> .message{
    padding: 0.5em 1em;
}
.information >>> ul{
    padding-inline-start: 20px;
}
</style>