import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/Index.vue')
  },
  {
    path: '/calculator',
    name: 'AppForm',
    component: () => import('@/views/AppForm.vue')
  }
]

const router = new VueRouter({
  routes,
   //スクロール位置を指定
   scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      //savePotisionがあるとvueデフォルトのスクロール挙動になるようです。（スクロール位置がページ遷移後も保存される）
      return savedPosition
    } else if (to.hash) {
      //router-linkで＃（id）指定リンクした場合
      return {
        selector: to.hash,
        offset: { x :0, y:200 }
      }
    } else {
      //savedPositionがなく、かつ、router-linkで＃を使用していない場合、スクロール位置はtopになります。
      return { x: 0, y: 0 }
    }
  }
})

export default router
