import Vue from 'vue'

/**
 * 計算用の値・結果管理モジュール
 * @module store/modules/calc
 */

const state = {
    region:'1',
    type:'1',
    uValue:1,
    underFloorPanel:'1',
    topHeatReleaseRate:0.00,
    rsiPlusRsu:0.269,
    hValue:0.00,
    rSiPlusRuPlusRpPlusRdPlusRse:0.00,
    rupAndH:0.00,
}

const getters = {
    calcData(state){
        return state
    },
}

const actions = {
    // stateの更新を呼び出す
    update({commit}, values) {
        commit('update', values)
    },
    calc({state, commit}){
        const values = {}

        values.rsiPlusRsu = 0.269
        values.hValue = getHvalue(state.underFloorPanel, state.region)
        values.rSiPlusRuPlusRpPlusRdPlusRse = 1 / parseFloat(state.uValue)
        values.rupAndH = getRupH(state.type, values.hValue, state.uValue)
        values.topHeatReleaseRate = Math.floor(values.rupAndH * 100).toFixed(0)
        
        commit('update', values)
    },
}

const mutations = {
    // stateの更新
    update(state, values) {
        Object.keys(values).forEach(key => {
            Vue.set(state, key, values[key])
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

function getHvalue(underFloorPanel, region) {
    switch(underFloorPanel){
        case '1':
            return 1.0
        
        case '2':
            return 0.7
        
        case '3':
            return parseInt(region) <= 3 ? 0.05 : 0.15
        
        default:
            return 0
    }
}

function getRupH(floorType, hValue, uValue){
    if (floorType == 2) return 0.90

    var Ui = parseFloat(uValue)
    if (Ui <= 0.0 || Ui >= 100) return 0.0

    var r = 1.0 - (hValue * 0.269 * Ui)
    return r
}