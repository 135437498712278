<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
*{
  margin: 0;
  box-sizing: border-box;
}
#app{
  min-width: 300px;
  min-height: 100vh;
  margin: 0;
  font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(83, 191, 216, 0.1);
  font-weight: 500;
  text-align: left;
  line-height: 1.5;
}
/* 文字間調整 */
p,span{
  font-feature-settings: "palt";
}
/* ボタンなどの青枠を消す */
input,
textarea,
select,
button {
  outline: none;
}
/* 書体が反映されていないものをフォロー */
button, input, select, textarea {
font-family : inherit;
font-size : 100%;
}
button,
input[type="button"]{
  cursor: pointer;
  outline:0;
}
label{
  color: #1C4373;
}
a{
  text-decoration: none;
}
.ly_buttonBox{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/* transitionのアニメーション */
.toast-enter-active, .toast-leave-active {
  transform: scale(1, 0); 
  transform-origin: bottom;
  transition: all .3s ease;
}
.toast-enter-to {
  transform:  scale(1, 1);
}


/* 印刷用cssのスタイル */
@media print{
  body {
    background:none;	/*ページ全体の背景色*/
    background-color: white;
    color: black;
  }
  div#app {
    width:100%;
    padding-top: 0;
    background:none;	/*ページ全体の背景色*/
    background-color: white;
    color: black !important;
  }
  main.bl_main.ly_main{
    margin: 0 auto;
    padding: 60px 32px;
  }
  header#header.ly_header{
    background-color: white;
    color: black !important;
  }
  header#header.ly_header *{
    background-color: white;
    color: black !important;
  }
  header#header.ly_header .ly_header_inner{
    padding-left:32px;
  }
  header#header.ly_header .version{
    border: 1px solid black;
  }
  header#header.ly_header .bl_header_inner_icon{
    display: none;
  } 
  .bl_wrapper__2column .section_wrap .app_section{
    border-color: black;
    color: black;
  }
  .bl_main .bl_wrapper .section_wrap h3{
    color: black;
  }

  .bl_main .bl_wrapper .app_box .grid_1{
    color: black;
    border-bottom: 1px solid black;
  }
  .bl_main .bl_wrapper .app_box span{
    color: black;
  }
  .radio_wrapper label{
    color: black;
  }
  .radio_wrapper label.radio_label.checked:before{
    color: black;
  }
  .bl_result .section_wrap section.app_section{
    background-color: white;
  }
  .bl_result .bl_resultMenu_inner__summary .bl_resultMenu_inner_result {
    border-color: black;
  }
  .bl_result .bl_calcProcess .bl_calcProcess_list:not(:last-child){
    border-color: black;
  }
}
</style>
